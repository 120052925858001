<template>
  <div class="container-footer">
    <div class="footer-main">
      <dl>
        <dt>焱十一</dt>
        <dd>
          <a href="javascript:;" @click="about()">关于我们</a>
        </dd>
        <dd>
          <a href="javascript:;" @click="privacy()">隐私政策
          </a>
        </dd>
        <dd>
          <a href="javascript:;" @click="contactUs()">联系我们
          </a>
        </dd>
        <dd>
          <a href="javascript:;" @click="message()">意见反馈
          </a>
        </dd>
      </dl>
      <dl>
        <dt>竞买人</dt>
        <dd>
          <a href="javascript:;" @click="auctionLogin()">买家注册
          </a>
        </dd>
        <dd>
          <a href="javascript:;" @click="help()">帮助中心
          </a>
        </dd>
        <dd>
          <a href="javascript:;" @click="dateGoo()">拍卖日历
          </a>
        </dd>
        <dd>
          <a href="javascript:;" @click="ServiceRule()">服务规则
          </a>
        </dd>
      </dl>
      <dl>
        <dt>拍卖机构</dt>
        <dd>
          <a href="javascript:;" @click="auctionHouseCheckin()">拍卖行入驻
          </a>
        </dd>
        <dd>
          <a href="javascript:;" @click="auctionLogin()">拍卖行登录
          </a>
        </dd>
        <dd>
          <a href="javascript:;" @click="auctionHouse()">拍卖行列表
          </a>
        </dd>
        <dd>
          <a href="javascript:;" @click="Useguide()">使用指南
          </a>
        </dd>
      </dl>
      <dl>
        <dt>焱十一</dt>
        <div class="code">
          <dd>
            <img src="../assets/images/mockApp.png" alt="">
            <span>APP</span>
          </dd>
          <dd>
            <img src="../assets/images/ewmCode.png" alt="">
            <span>公众号</span>
          </dd>
        </div>
      </dl>
    </div>
    <div class="footer-main-bottom">北京艺得在线科技有限公司&nbsp;京ICP备2021028459号-1</div>
    <div style="width:300px;margin:0 auto; padding:20px 0;">
      <a target="_blank" href=" " style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
        <img src="../assets/images/beian.png" style="float:left;" />
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11011502005176号
        </p>
      </a>
    </div>
    <login ref="login" />
  </div>
</template>
<script>
import login from './login'
export default {
  name: 'Footer',
  components: {
    login
  },
  data () {
    return {

    }
  },
  methods: {
    // 使用指南
    Useguide () {
      this.$router.push({
        path: '/Useguide',
        name: 'Useguide'
      })
    },
    // 隐私政策
    privacy () {
      this.$router.push({
        path: '/privacy',
        name: 'Privacy'
      })
    },
    // 帮助中心
    help () {
      this.$router.push({
        path: '/helpmiddle',
        name: 'Helpmiddle'
      })
    },
    // 跳转全球拍卖会
    dateGoo () {
      this.$router.push({
        path: '/auctionhome',
        name: 'Auctionhome'
      })
    },
    // 拍卖行登录
    auctionLogin () {
      if (!localStorage.getItem('accessToken')) {
        this.$nextTick(() => {
          this.$refs.login.open()
        })
      } else {
        this.$message.success('您已登录')
      }
    },
    // 拍卖行入驻
    auctionHouseCheckin () {
      this.$router.push({
        path: '/auctionhousecheckin',
        name: 'Auctionhousecheckin'
      })
    },
    // 拍卖行列表
    auctionHouse () {
      this.$router.push({
        path: '/auctionhouse',
        name: 'Auctionhouse'
      })
    },
    // 服务规则
    ServiceRule () {
      this.$router.push({
        path: '/servicerule',
        name: 'Servicerule'
      })
    },
    // 意见反馈
    message () {
      if (!localStorage.getItem('accessToken')) {
        this.$nextTick(() => {
          this.$refs.login.open()
        })
      } else {
        if (localStorage.getItem('auctionHouseLoginNum') === '1') {
          this.$message.error('拍卖行暂不支持反馈')
        } else {
          this.$router.push({
            path: '/personalcenter',
            name: 'Personalcenter',
            query: {
              marking: 3
            }
          })
        }
      }
    },
    // 关于我们
    about () {
      this.$router.push({
        path: '/aboutus',
        name: 'Aboutus'
      })
    },
    // 联系我们
    contactUs () {
      this.$router.push({
        path: '/contactus',
        nmae: 'Contactus'
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .container-footer {
    background: #ffffff;
    box-shadow: 0px -2px 19px 2px rgba(173, 173, 173, 0.05);
    height: 363px;
    box-sizing: border-box;
    padding: 59px 438px 60px 438px;

    .code {
      display: flex;

      dd {
        margin-right: 30px;
        text-align: center;
        font-size: 16px;

        img {
          width: 96px;
          margin-bottom: 10px;
        }
      }
    }

    .footer-main {
      display: flex;
      justify-content: space-around;
      margin-bottom: 60px;

      dl {
        display: inline-block;
        width: 140px;
        text-align: left;

        dt {
          font-size: 20px;
          color: #333;
          font-weight: 400;
        }

        dd {
          margin-top: 15px;
          font-size: 16px;

          a {
            color: #333;
          }
        }
      }
    }

    .footer-main-bottom {
      text-align: center;
      // margin-bottom: 58px;
    }
  }
</style>
