<template>
  <div class="container-headerbar" id="disableRightClicking">
    <div class="header-top">
      <div class="header-top-left-1">
        客服电话：400-616-9988
      </div>
      <div class="header-top-right-2">
        <a href="javascript:;" @click="termagreement(1)">关于我们</a>
        <a href="javascript:;" @click="termagreement(2)">服务特色</a>
        <a href="javascript:;" @click="termagreement(3)">帮助中心</a>
        <a href="javascript:;" @mouseover="hoverImg" @mouseout="hoverOut">app下载</a>
        <a href="javascript:;" @mouseover="hoverImgT" @mouseout="hoverOutT">公众号</a>

        <img src="../assets/images/mockApp.png" v-if="hoverShow" class="hoverOut-1" alt="" />

        <img src="../assets/images/ewmCode.png" v-if="hoverShowT" class="hoverOut-2" alt="" />
      </div>
    </div>
    <div class="header">
      <div class="header-logo" @click="toGoHome()">
        <img src="../assets/images/logo.png" alt="" />
      </div>
      <div class="header-middle">

        <div class="input-box">
          <input type="text" placeholder="请输入您要查询的内容" v-model="searchDataReference.content" @keyup.enter="enterSearch"
            @input="searchInput()" />
          <span class="search-btn" @click="enterSearch()">搜索</span>
        </div>
        <div class="search-con"
          v-show="isShow&&searchDataReference.content!=''&&(itemList.length>0||auctionHouseList.length>0)">
          <template v-if="itemList.length>0">
            <div class="title">拍品</div>
            <div class="search-list">
              <router-link v-for="item in itemList" class="searchc" :key="item.id" target="_blank"
                :to="{path:'/productdetails',query:{productdetailsId:item.id}}">
                Lot {{item.lot}}：{{item.title}}
              </router-link>
            </div>
          </template>
          <template v-if="auctionHouseList.length>0">
            <div class="title">拍卖行</div>
            <div class="search-list">
              <router-link v-for="item in auctionHouseList" class="searchc" :key="item.id" target="_blank" :to="{
                path: '/auctionhousedetails',
                query: { auctionhousedetailsid: item.id }
              }">
                {{item.name}}
              </router-link>
            </div>
          </template>
        </div>
        <!--  <a-select placeholder="请选择条件" style="width: 120px" :label-in-value='true' @change="handleChange">
          <a-select-option :value="item.id" v-for="(item,index) in searchData" :key="index">
            {{item.name}}
          </a-select-option>
        </a-select> -->
      </div>
      <div class="header-login">
        <div class="header-collect" @click="handleCollect()"
          v-if="auctionHouseLoginNum !== 1&&auctionHouseLoginNum !== 3">
          <a href="javascript:" style="padding-right:4px;"><img src="../assets/images/aixin.png" alt="" /></a>
          <a href="javascript:">收藏</a>
        </div>
        <div class="header-collect" @click="handleCollectMessage()"
          v-if="auctionHouseLoginNum !== 1&&auctionHouseLoginNum !== 3">
          <a href="javascript:" style="padding-right:4px;"><img src="../assets/images/tixing.png" alt="" /></a>
          <a href="javascript:">消息</a>
          <div class="riduis" v-if="unreadCount !== 0 && tokenShowOne">
            <!-- <span v-if="unreadCount<99">{{unreadCount}}</span>
            <span v-else>99+</span> -->
          </div>
        </div>
        <div v-if="tokenShow" class="login-and-registration">
          <div class="header-login-login" @click="login()">
            <a href="javascript:" style="padding-right:4px;"><img src="../assets/images/wode.png" alt="" /></a>
            <a href="javascript:">登录</a>
          </div>

          <div class="header-line" v-if="tokenShow"></div>

          <div class="header-registration" @click="registration()">
            <a href="javascript:">注册</a>
          </div>
        </div>
        <div class="avatr-show" v-else @click="togoPersonalcenter">
          <img :src="[
              Imagedefult === ''
                ? require('../assets/images/25.png')
                : Imagedefult
            ]" alt="" />
        </div>
      </div>
    </div>
    <div class="navigation">
      <div class="navigation-left">
        <ul>
          <li>
            <a href="javascript:" @click="home()" :class="{ navigationColor: activeId === 1000000001 }">首页</a>
          </li>
          <li>
            <a href="javascript:" @click="auction" :class="{ navigationColor: activeId === 1000000002 }">全球拍卖会</a>
          </li>
          <li>
            <a href="javascript:" @click="auctionhouse()" :class="{ navigationColor: activeId === 1000000003 }">拍卖行</a>
          </li>
        </ul>
      </div>
      <div class="navigation-middle">
        <ul>
          <li v-for="(item, index) in classifyDate" :key="index">
            <a-dropdown>
              <!-- <router-link target="_blank" :to="{path:'/overlay',query:{overlatId:item.id}}">{{item.chineseName}}
              </router-link> -->

              <a class="ant-dropdown-link" @click="goOverlay(item.parentId, item.id, item.name)"
                :class="{ navigationColor: activeId === item.id }">
                {{ item.chineseName }}
              </a>
              <a-menu slot="overlay" v-if="item.son !== null">
                <a-menu-item v-for="(item, index) in item.son" :key="index" style="font-size:0.07375rem;"
                  @click="goOverlay(item.parentId, item.id, item.name, 2)">
                  {{ item.chineseName }}（{{ item.itemNum }}）
                </a-menu-item>
                <!-- <a-menu-item v-for=" (item,index) in item.son" :key="index" style="font-size:0.07375rem;">

                  <router-link target="_blank" :to="{path:'/overlay',query:{overlatId:item.id}}">
                    {{item.chineseName}}（{{item.itemNum}}）
                  </router-link>
                </a-menu-item> -->
              </a-menu>
            </a-dropdown>
          </li>
        </ul>
      </div>
      <!-- <div class="navigation-right">
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <img src="../assets/images/gengduo.png" class="el-dropdown-link" alt="">
          </a>
          <a-menu slot="overlay">
            <a-sub-menu :title="item.chineseName" v-for=" (item,index) in all" :key="index" click="goOverlay(item.id)">
              <a-menu-item v-for="(item,index) in item.son" :key="index" @click="goOverlay(item.id)">
                {{item.chineseName}}（{{item.itemNum}}）</a-menu-item>
            </a-sub-menu>
          </a-menu>
        </a-dropdown>

      </div> -->
    </div>
    <Login ref="login" />
    <Registration ref="registration" />
  </div>
</template>

<script>
  import Login from './login.vue'
  import Registration from './Registration.vue'
  import {
    classify,
    search,
    userInfo,
    unreadMessage,
    searchPrompt
  } from '../api/index'
  import eventBus from '../utils/eventBus'
  export default {
    name: 'HeaderBar',
    inject: ['reload'],
    components: {
      Login,
      Registration
    },
    data() {
      return {
        tokenShow: true,
        tokenShowOne: false,
        all: [],
        classifyDate: [],
        unreadCount: '',
        labelInValue: true,
        // searchData: [
        //   {
        //     id: 1,
        //     name: '拍卖行'
        //   },
        //   {
        //     id: 2,
        //     name: '拍卖会'
        //   },
        //   {
        //     id: 3,
        //     name: '拍品'
        //   }
        // ],
        searchDataReference: {
          content: ''
        },
        unreadMessageData: {
          page: 1,
          pageSize: 1,
          status: 0,
          userId: Number(localStorage.getItem('accessId')),
          x_user_id: Number(window.localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        },
        Imagedefult: '',
        // image: require('')
        hoverShow: false,
        hoverShowT: false,
        auctionHouseLoginNum: 0,
        activeId: -1,
        auctionHouseList: [], //拍卖行搜索内容
        itemList: [], //拍品搜索内容
        isShow: true
      }
    },
    created() {
      this.yesToken()
      this.classify()
      this.auctionHouseLoginNum = Number(
        localStorage.getItem('auctionHouseLoginNum')
      )
      if (sessionStorage.getItem('activeId')) {
        this.activeId = Number(sessionStorage.getItem('activeId'))
      }
    },
    mounted() {
      eventBus.$on('unreadNum', data => {
        this.unreadCount = data
      })
      // document.getElementById('disableRightClicking').oncontextmenu = function (
      //   e
      // ) {
      //   return false
      // }
    },

    methods: {
      async searchInput() {
        this.isShow = true
        if (this.searchDataReference.content == '') {
          this.auctionHouseList = []
          this.itemList = []
          return
        }
        const res = await searchPrompt({
          userid: Number(window.localStorage.getItem('accessId')),
          x_user_id: Number(window.localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          content: this.searchDataReference.content,
          page: 1,
          pageSize: 4
        })
        if (res.code === 0) {
          this.auctionHouseList = res.data.auctionHouseList || []
          this.itemList = res.data.itemList || []
        } else {
          this.$message.error(res.msg)
        }
      },
      // 点击收藏
      handleCollect() {
        if (!localStorage.getItem('accessToken')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
        } else {
          this.reload()
          localStorage.setItem('informationShow', 1)
          this.$router.push({
            path: '/personalcenter',
            name: 'Personalcenter'
          })
        }
      },
      // 跳转消息通知
      handleCollectMessage() {
        if (!localStorage.getItem('accessToken')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
        } else {
          this.reload()
          localStorage.setItem('informationShow', 10)
          this.$router.push({
            path: '/personalcenter',
            name: 'Personalcenter'
          })
        }
      },
      // 首页跳转
      home() {
        this.activeId = 1000000001
        sessionStorage.setItem('activeId', 1000000001)
        this.$router.push({
          path: '/homemain',
          name: 'Homemain'
        })
      },
      // 获取用户信息
      async userInfo(id) {
        const res = await userInfo({
          userid: Number(window.localStorage.getItem('accessId')),
          x_user_id: Number(window.localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.Imagedefult = res.data.image
          this.unreadMessage()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 回车搜索
      enterSearch() {
        this.isShow = false
        if (this.searchDataReference.content === '') {
          this.$message.error('请输入内容')
          return
        }
        sessionStorage.setItem(
          'content',
          JSON.stringify(this.searchDataReference.content)
        )
        this.$router.push({
          path: '/search',
          name: 'Search',
          query: {
            content: this.searchDataReference.content
          }
        })
        const time = setInterval(() => {
          // this.reload()
          this.searchDataReference.content = JSON.parse(
            sessionStorage.getItem('content')
          )
          clearInterval(time)
        }, 500)
      },
      // 获取数据列表
      async unreadMessage() {
        const res = await unreadMessage(this.unreadMessageData)
        if (res.code === 0) {
          this.unreadCount = res.data.unread
        } else {
          this.$message.error(res.msg)
        }
      },
      // 选择搜索条件
      // handleChange (value, label) {
      //   this.searchDataReference.type = value.key
      // },
      // allQ () {
      //   for (let index = 0; index < this.classifyDate.length; index++) {
      //     if (this.classifyDate[index].son === null) {
      //       return
      //     }
      //     if (this.classifyDate[index].son[0].name === '全部') {
      //       return
      //     }
      //     const allData = {
      //       id: 0,
      //       name: '全部'
      //     }
      //     this.classifyDate[index].son.unshift(allData)
      //     console.log(this.classifyDate)
      //   }
      // },
      // 判断是否有无token
      yesToken() {
        if (!localStorage.getItem('accessToken')) {
          this.tokenShow = true
          this.tokenShowOne = false
          // this.reload()
        } else {
          this.tokenShow = false
          this.tokenShowOne = true
          if (Number(localStorage.getItem('auctionHouseLoginNum')) === 2) {
            this.userInfo()
          }
        }
      },
      // 分类跳转
      goOverlay(parentId, id, name, index) {
        this.activeId = id
        sessionStorage.setItem('activeId', id)
        localStorage.setItem('nameClass', name)
        if (index === 2) {
          for (let index = 0; index < this.classifyDate.length; index++) {
            for (let j = 0; j < this.classifyDate[index].son.length; j++) {
              if (this.classifyDate[index].son[j].parentId === parentId) {
                this.activeId = this.classifyDate[index].id
                sessionStorage.setItem('activeId', this.activeId)
              }
            }
          }
        }
        this.$router.push({
          path: '/overlay',
          name: 'Overlay',
          query: {
            overlatId: id,
            parentId: parentId
          }
        })
      },
      // 分类列表初始化数据
      async classify() {
        const res = await classify({
          id: 0,
          x_user_id: 0,
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.classifyDate = res.data.list
          this.all = res.data.list.slice(6)
          // this.allQ()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 前往个人中心
      async togoPersonalcenter() {
        localStorage.setItem('informationShow', 2)
        if (localStorage.getItem('accessToken')) {
          // this.reload()
          // 用户登录
          if (this.auctionHouseLoginNum === 2) {
            this.$router.push({
              path: '/personalcenter',
              name: 'Personalcenter'
            })
          } else if (this.auctionHouseLoginNum === 1) {
            // 拍卖行登录
            this.$router.push({
              path: '/auctionpersonalcenter',
              name: 'Auctionpersonalcenter'
            })
          } else {
            //商户
            this.$router.push({
              path: '/mallBusinessCenter',
              name: 'mallBusinessCenter'
            })
          }
        } else {
          var timer = setTimeout(() => {
            location.reload()
            clearInterval(timer)
          }, 500)
          this.$message.error('请您先登录')
        }
      },
      // 跳转首页
      toGoHome() {
        this.activeId = 1000000001
        sessionStorage.setItem('activeId', 1000000001)
        this.$router.push({
          path: '/homemain',
          name: 'Homemain'
        })
      },
      // 跳转拍卖会
      auction() {
        if (this.activeId == 1000000002) {
          eventBus.$emit('refreshAllAuction') //加载全部
        } else {
          this.activeId = 1000000002
          sessionStorage.setItem('activeId', 1000000002)
          this.$router.push({
            path: '/auctionhome'
          })
        }


      },
      // 跳转拍卖行
      auctionhouse() {
        this.activeId = 1000000003
        sessionStorage.setItem('activeId', 1000000003)

        this.$router.push({
          path: '/auctionhouse',
          name: 'Auctionhouse'
        })
      },
      // 登录
      login() {
        this.$nextTick(() => {
          this.$refs.login.open()
        })
      },
      // 注册
      registration() {
        this.$nextTick(() => {
          this.$refs.registration.open()
        })
      },
      termagreement(index) {
        if (index === 1 || index === 2) {
          this.$router.push({
            path: '/aboutus',
            name: 'Aboutus',
            query: {
              aboutId: JSON.stringify(index)
            }
          })
        } else if (index === 3) {
          this.$router.push({
            path: '/helpmiddle',
            name: 'Helpmiddle'
          })
        }
      },
      hoverImg() {
        this.hoverShow = true
      },
      hoverOut() {
        this.hoverShow = false
      },
      hoverImgT() {
        this.hoverShowT = true
      },
      hoverOutT() {
        this.hoverShowT = false
      }
    }
  }
</script>

<style scoped lang="less">
  .el-dropdown-link {
    // width: 16px;
    cursor: pointer;
    color: #cda156;
  }

  .container-headerbar {
    .navigationColor {
      // font-weight: 600;
      color: #cda156 !important;
    }

    /deep/ .ant-select-selection {
      height: 50px;
      border: 1px solid #999999;
      transform: translateY(-10px);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    /deep/ .ant-select-selection-selected-value {
      line-height: 50px;
    }

    /deep/ .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      top: 83%;
    }

    /deep/ .ant-dropdown-menu-item {
      font-size: 12px !important;
    }

    .header-top {
      background: #f9f9f9;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 83px;
      padding-left: 49px;
      color: #666;
      font-size: 12px;

      .header-top-right-2 {
        position: relative;

        a {
          color: #666;
          padding-left: 30px;
        }

        .hoverOut-1 {
          width: 67px;
          z-index: 1000;
          position: absolute;
          top: 21px;
          right: 55px;
          /*no*/
          // left: 1.63rem;
        }

        .hoverOut-2 {
          width: 67px;
          position: absolute;
          top: 21px;
          right: -8px;
          /*no*/
          z-index: 1000;
        }
      }
    }

    .header {
      height: 92px;
      display: flex;
      align-items: center;
      //justify-content: space-around;
      position: relative;

      .header-logo {
        height: 60px;
        margin-left: 90px;

        img {
          height: 100%;
        }
      }

      .header-middle {
        height: 42px;
        margin-left: 213px;

        .search-con {
          position: absolute;
          z-index: 99;
          width: 760px;
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2000);
          border-radius: 4px;
          margin-top: 5px;
          padding: 7px 20px 0 20px;

          .title {
            color: #666666;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            margin-bottom: 10px;
            margin-top: 5px;
          }

          .search-list {
            display: flex;
            flex-wrap: wrap;
          }

          .searchc {
            height: 30px;
            padding: 0 10px;
            background: #F3F3F3;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            margin-right: 20px;
            margin-bottom: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .input-box {
          &:focus-within {
            border: 1px solid #cda156;
          }

          width: 760px;
          height: 100%;
          border: 1px solid #bfc3c4;
          // border-top: 1px solid #999999;
          // border-bottom: 1px solid #999999;
          // border-left: 1px solid #999999;
          // border-right: none;
          border-radius: 8px;
          // border-bottom-left-radius: 8px;
          color: #999999;
          line-height: 42px;
          font-size: 16px;
          text-align: left;
          // outline: none;
          background-image: url('../assets/images/sousuo@2x.png');
          background-repeat: no-repeat;
          background-size: 22px 22px;
          background-position: 22px;
          padding-left: 50px;
          font-size: 16px;
          //outline-color: #cda156;
          display: flex;
          align-items: center;

          input {
            height: 100%;
            width: 100%;
            border: none;
            outline: none;
          }

          .search-btn {
            width: 80px;
            height: 38px;
            background: #CDA156;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }


        select {
          width: 100px;
          height: 100%;
          transform: translateY(-1px);
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }

      .header-login {
        display: flex;
        align-items: center;
        position: absolute;
        right: 66px;

        // width: 380px;
        // justify-content: flex-end;
        .avatr-show {
          display: flex;
          align-items: center;
          margin-left: 20px;

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }

        .header-collect {
          // width: 74px;
          height: 40px;
          border-radius: 8px;
          // border: 1px solid #999999;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
          position: relative;
          font-size: 16px;

          .riduis {
            width: 8px;
            height: 8px;
            background: red;
            border-radius: 50%;
            color: #fff;
            line-height: 15px;
            text-align: center;
            position: absolute;
            top: 8px;
            left: 52px;

            span {
              font-size: 12px;
            }
          }

          img {
            width: 18px;
            height: 18px;
            transform: translateY(-2px);
          }

          a {
            color: #666;
            font-size: 16px;
          }
        }

        .header-line {
          height: 20px;
          margin: 0 10px;
          border: 1px solid #999;
        }

        .login-and-registration {
          display: flex;
          align-items: center;
        }

        .header-registration {
          a {
            font-size: 16px;
            color: #666;
          }
        }

        .header-login-login {
          // width: 74px;
          height: 40px;
          // background: #cda156;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #666;
          margin-left: 20px;

          img {
            width: 18px;
            height: 18px;
            transform: translateY(-2px);
          }

          a {
            color: #666;
          }
        }
      }
    }

    .navigation {
      height: 52px;
      display: flex;
      border-top: 1px solid #bfc3c4;
      border-bottom: 1px solid #bfc3c4;
      padding: 0 50px;

      .navigation-left {

        // width: 410px;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          // box-shadow: 4px 0px 16px 0px rgba(0, 0, 0, 0.03);
          margin-bottom: 0;

          li {
            // width: 50%;
            font-size: 0.09375rem;
            color: #333;
            padding: 0 49.5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            a {
              color: #333;
            }

            a:hover {
              color: #cda156;
            }
          }

          li:nth-child(3) {
            border-right: 1px solid #999;
          }
        }
      }

      .navigation-middle {
        height: 100%;

        ul {
          // width: 1300px;
          // height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;
          line-height: 50px;

          li {
            // width: 20%;
            font-size: 0.09375rem;
            padding: 0 49.5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            a {
              color: #333;
            }

            a:hover {
              color: #cda156;
            }

            img {
              width: 30px;
              height: 24px;
            }
          }
        }
      }

      .navigation-right {
        width: 210px;
        display: flex;
        align-items: center;
        justify-content: center;

        a:hover {
          color: #cda156;
        }
      }
    }
  }
</style>
