<template>
  <div class="container-topright">
    <a-popover placement="left">
      <template slot="content">
        <img src="../assets/images/ewmCode.png" class="codeImg" />
        <div class="context">公众号</div>
      </template>
      <div class="imgBg">
        <img src="../assets/images/weixin.png" class="icon-wechat" />
      </div>
    </a-popover>

    <a-popover placement="left">
      <template slot="content">
        <img src="../assets/images/mockApp.png" class="codeImg" />
        <div class="context">app下载</div>
      </template>
      <div class="imgBg">
        <img src="../assets/images/shouji.png" class="icon-phone" />
      </div>
    </a-popover>

    <img src="../assets/images/16.png" alt="" v-if="hide" @click="feedback()">
    <a-popover placement="left">
      <template slot="content">
        <p>公司名称：北京艺得在线科技有限公司</p>
        <p>公司地址：北京市大兴区亦庄经济开发区荣京东街3号荣京丽都A座415室</p>
        <p>邮编：100176</p>
        <p>客服邮箱：info@easyebid.com</p>
        <p>客服电话：400-616-9988</p>
        <p>工作时间：周一至周五上午9:00-12:00，下午2:00-6:00（法定节假日除外）</p>
      </template>
      <template slot="title">
        <span>联系我们</span>
      </template>
      <img src="../assets/images/15.png" alt="">
    </a-popover>
    <img src="../assets/images/14.png" alt="" @click="backTop()">
  </div>
</template>
<script>
export default {
  name: 'Topright',
  inject: ['reload'],
  data () {
    return {
      gotop: false,
      hide: true
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  created () {
    if (localStorage.getItem('auctionHouseLoginNum') === '1') {
      this.hide = false
    }
  },
  methods: {
    handleScroll (e) {
      const scrolltop = e.target.scrollTop
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false)
    },
    backTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 0)
    },
    // 跳转反馈
    feedback () {
      if (!localStorage.getItem('accessToken')) {
        this.$message.error('请先登录')
        return
      }
      this.reload()
      this.$router.push({
        path: '/personalcenter',
        name: 'Personalcenter',
        query: {
          marking: 3
        }
      })
    }

  }
}
</script>
<style lang="less" scoped>
  .codeImg {
    width: 80px;
    height: 80px;
  }

  .context {
    text-align: center;
  }

  .container-topright {
    position: fixed;
    right: 10px;
    bottom: 14%;
    display: flex;
    flex-direction: column;
    z-index: 5;

    .imgBg {
      margin-bottom: 5px;
      width: 40px;
      height: 36px;
      background: #6C6C6C;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-wechat {
        width: 24px;
        height: 20px;
      }

      .icon-phone {
        width: 18px;
        height: 24px;
      }
    }

    img {
      border-radius: 2px;
      margin-bottom: 5px;
      width: 40px;
      height: 36px;
    }
  }
</style>
