<template>
  <div class="home">
    <!-- 导航头部 -->
    <HeaberBar
      v-if="$router.history.current.name !== 'Liveauctionhouse' && $router.history.current.name !==  'Buyerlive'" />
    <!-- 面包屑 -->
    <!-- <Breadcrumb v-if="dialogVisible"></Breadcrumb> -->
    <!-- 二级路由挂载点 -->
    <div class="content">
      <transition name="move" mode="out-in">

        <router-view :key="$route.fullpath"></router-view>

      </transition>
    </div>
    <!-- 底部 -->
    <Footer
      v-if="$router.history.current.name !== 'Liveauctionhouse' && $router.history.current.name !==  'Buyerlive'" />
    <!-- 右下角 -->
    <Topright
      v-if="$router.history.current.name !== 'Liveauctionhouse' && $router.history.current.name !==  'Buyerlive'" />
  </div>
</template>
<script>
import HeaberBar from '@/components/HeaderBar.vue'
import Footer from '@/components/Footer.vue'
import Topright from '@/components/Topright.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
export default {
  name: 'Home',
  components: {
    HeaberBar,
    Footer,
    Topright,
    Breadcrumb
  },
  data () {
    return {
      dialogVisible: null
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler (val, oldVal) {
        if (val.fullPath === '/homemain' || val.fullPath === '/personalcenter') {
          this.dialogVisible = false
        } else {
          this.dialogVisible = true
        }
      }
    }
  }
}
</script>
