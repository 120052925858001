<template>
  <div class="container-editnicknames" v-if="visible">
    <!-- <div ref="refModel">
      <a-modal :getContainer="() => $refs.refModel" title="注册" :visible="visible" @cancel="handleCancel">
        <a-input placeholder="请输入手机号码" v-model="regisTrationDate.mobile" />
        <div class="code">
          <a-input placeholder="请输入验证码" v-model="regisTrationDate.code" />
          <button :disabled='btnBool' @click="onCode">{{yzmtext}}</button>
        </div>
        <template slot="footer">
          <div class="ok">
            <a-button @click="regisTration()">注册</a-button>
            <a-button @click="regisTration()">注册</a-button>
            <p class="top-login ">
              <el-checkbox v-model="checked"></el-checkbox>
              <span>我已阅读并同意</span>
              <a href="javascript:;" style="color:#cda156;" @click="termagreement()">《条款与协议》</a>
            </p>
          </div>
        </template>
      </a-modal>
    </div> -->
    <div class="ant-modal-mask" @click.stop="visibleFalse"></div>
    <div class="model">
      <div class="off-cha">
        <span class="iconfont icon-shanchu" @click="offModel"></span>
      </div>

      <div class="middle-login">
        <div class="table-chose">
          <a href="javascript:;" class="active">用户注册</a>
        </div>
        <div class="user-login">
          <a-input placeholder="请输入手机号码" v-model="regisTrationDate.mobile" />
          <div class="code">
            <a-input placeholder="请输入验证码" v-model="regisTrationDate.code" />
            <button :disabled='btnBool' @click="onCode">{{yzmtext}}</button>
          </div>
          <div class="ok">
            <div class="top-login ">
              <button class="login-auction" @click="regisTration()">注册</button>
              <div class="arrgee">
                <el-checkbox v-model="checked"></el-checkbox>
                <span>我已阅读并同意</span>
                <a href="javascript:;" style="color:#cda156;" @click="termagreement()">《条款与协议》</a>
              </div>
            </div>
            <button class="auction-rz" @click="auctionHouseCheckin()">拍卖行入驻</button>
            <!-- <button class="auction-rz2" @click="shopCheckin()">商家入驻</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    userLogin,
    userSendCode
  } from '../api/index'
  export default {
    name: 'Editnicknames',
    data() {
      return {
        visible: false,
        regisTrationDate: {
          mobile: '',
          code: '',
          type: '1'
        },
        yzmtext: '获取验证码',
        btnBool: false,
        checked: true,
        time: null
      }
    },
    destroyed() {
      clearInterval(this.time)
    },
    methods: {
      // 注册按钮
      async regisTration(e) {
        if (this.checked === false) {
          this.$message.error('请勾选协议')
          return
        }
        if (this.regisTrationDate.mobile === '') {
          this.$message.error('请输入手机号码')
          return
        }
        if (!/^((1[3,5,6,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(this.regisTrationDate.mobile)) {
          this.$message.error('请输入正确手机号')
          return
        }
        if (this.regisTrationDate.code === '') {
          this.$message.error('请输入验证码')
          return
        }
        if (this.regisTrationDate.code.length !== 4) {
          this.$message.error('验证码位数不正确')
          return
        }
        const res = await userLogin(this.regisTrationDate)
        if (res.code === 0) {
          if (res.data.oldUser === true) {
            this.$message.error('您已经注册过了！')
            this.$store.commit('setToken', res.data.accessToken)
            this.$store.commit('setUserId', res.data.id)
            window.localStorage.setItem('accessId', Number(res.data.id))
            localStorage.setItem('accessToken', res.data.accessToken)
            this.visible = false
            this.regisTrationDate.mobile = ''
            this.regisTrationDate.code = ''
            var timer = setTimeout(() => {
              location.reload()
              clearInterval(timer)
            }, 500)
            this.$message.success('登录成功~')
          } else {
            this.visible = false
            this.regisTrationDate.mobile = ''
            this.regisTrationDate.code = ''
            this.$message.success('注册成功，正在登录')
            this.$store.commit('setToken', res.data.accessToken)
            this.$store.commit('setUserId', res.data.id)
            window.localStorage.setItem('accessId', Number(res.data.id))
            localStorage.setItem('accessToken', res.data.accessToken)
            this.visible = false
            this.regisTrationDate.mobile = ''
            this.regisTrationDate.code = ''
            var timerLogin = setTimeout(() => {
              location.reload()
              clearInterval(timerLogin)
            }, 500)
          }
        } else {
          this.$message.error(res.msg)
        }
      },
      // 关闭弹窗
      handleCancel(e) {
        this.visible = false
      },
      open() {
        this.visible = true
      },
      // 点击获取验证码
      async onCode(value) {
        if (this.regisTrationDate.mobile === '') {
          this.$message.error('请输入手机号')
          return
        }
        if (!/^((1[3,5,6,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(this.regisTrationDate.mobile)) {
          this.$message.error('请输入正确手机号')
          return
        }
        this.btnBool = true
        let i = 60
        var timer = setInterval(() => {
          this.yzmtext = '' + i + 's'
          i--
          if (i < 0) {
            this.btnBool = false
            this.yzmtext = '获取验证码'
            clearInterval(timer)
          }
        }, 1000)
        const params = {
          mobile: this.regisTrationDate.mobile
        }
        const res = await userSendCode(params)
        if (res.code === 0) {
          this.$message.success('发送成功')
        } else {
          this.$message.error('发送失败')
        }
      },
      termagreement() {
        this.$router.push({
          path: '/termagreement',
          name: 'Termagreement'
        })
      },
      visibleFalse() {
        this.visible = false
      },
      offModel() {
        this.visible = false
      },
      // 拍卖行入驻
      auctionHouseCheckin() {
        this.visible = false
        this.$router.push({
          path: '/auctionhousecheckin',
          name: 'Auctionhousecheckin'
        })
        if (this.$route.path === '/auctionhousecheckin') {
          this.time = setTimeout(() => {
            localStorage.clear()
            location.reload()
          }, 500)
        }
      },
      //商家入驻
      shopCheckin() {
        this.visible = false
        this.$router.push({
          path: '/businessesIn',
          name: 'businessesIn'
        })
        if (this.$route.path === '/businessesIn') {
          this.time = setTimeout(() => {
            localStorage.clear()
            location.reload()
          }, 500)
        }
      }
    }

  }
</script>
<style lang="less" scoped>
  .container-editnicknames {
    .ant-modal-mask {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
    }

    .model {
      width: 600px;
      height: max-content;
      background: #fff;
      border-radius: 8px;
      position: fixed;
      margin: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;

      .off-cha {
        text-align: right;
        margin-right: 20px;
        margin-top: 20px;
      }

      .middle-login {
        padding: 0 140px 0 140px;

        .table-chose {
          padding-bottom: 39px;
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            font-size: 20px;
            font-weight: 400;
            color: #666;
          }

          span {
            padding: 8px;
            font-size: 20px;
          }

          .active {
            font-weight: 600;
          }
        }

        .auction-house {
          .input-a-p {
            display: flex;
            flex-direction: column;

            input {
              width: 320px;
              height: 50px;
              background: #fff;
              border: 1px solid #dedede;
              margin-bottom: 10px;
              font-size: 16px;
              color: #999;
              outline: none;
              padding: 14px;
              padding-left: 41px;
            }

            .my-user {
              background: url("../assets/images/zhanghaodenglu@2x.png") no-repeat;
              background-size: 16px;
              background-position: 13px;
            }

            .pw-user {
              background: url("../assets/images/mima-2@2x.png") no-repeat;
              background-size: 16px;
              background-position: 13px;
            }

            .forget-pw {
              color: #cda156;
              font-weight: 400;
              font-size: 12px;
              text-align: right;
              text-decoration: underline;
            }
          }
        }
      }
    }

    /deep/ .ant-modal-header {
      text-align: center;
      border: none;
    }

    /deep/ .ant-modal-footer {
      text-align: center;
      border: none;
    }

    /deep/ .el-checkbox__inner {
      border-color: #b3b0b0;
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #cda156;
    }

    /deep/ .ant-input:focus {
      border-color: #cda156;
    }

    .code {
      margin-top: 30px;

      /deep/ .ant-input {
        width: 200px;
        height: 40px;
        // margin-left: 115px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      button {
        width: 120px;
        height: 40px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        outline: none;
        color: #fff;
        background: #cda156;
        border: none;
      }

      /deep/ .ant-input-group-addon {
        background-color: #fff;
      }

      /deep/ .ant-btn {
        color: #fff;
        font-weight: 400;
        font-size: 16px;

        background: #cda156;
        border: none;
      }

      /deep/ .ant-btn {
        width: 120px;
        margin-right: 116px;
      }
    }

    /deep/ .ant-input {
      width: 320px;
      height: 40px;
    }

    /deep/ .ant-input:hover {
      border-color: #cda156;
    }

    /deep/ .ant-input:focus {
      box-shadow: none;
    }

    .ok {
      /deep/ .ant-btn {
        width: 140px;
        height: 50px;
        color: #6e5121;
        font-weight: 600;
        font-size: 20px;
        background: #f5edde;
        border: none;
        margin-top: 20px;
      }

      .auction-rz {
        width: 320px;
        color: #cda156;
        font-weight: 500;
        font-size: 18px;
        border: 1px solid #cda156;
        border-radius: 8px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        background: #fff;
        margin-top: 30px;
        margin-bottom: 40px;
      }

      .auction-rz2 {
        width: 320px;
        color: #cda156;
        font-weight: 500;
        font-size: 18px;
        border: 1px solid #cda156;
        border-radius: 8px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        background: #fff;
        margin-top: 30px;
        margin-bottom: 40px;
      }

      .top-login {
        margin-top: 10px;
        font-size: 18px;
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;

        span {
          padding-left: 5px;
        }

        .login-auction {
          width: 320px;
          color: #6e5121;
          font-weight: 600;
          font-size: 18px;
          border-radius: 8px;
          background: #f5edde;
          height: 44px;
          text-align: center;
          line-height: 44px;
          border: none;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }

    /deep/ .ant-modal-content {
      width: 600px;
      height: 393px;
    }

    /deep/ .ant-modal-title {
      font-size: 22px;
      margin-top: 30px;
    }

    /deep/ .ant-modal-body {
      margin-top: 20px;
    }
  }
</style>
