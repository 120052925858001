<template>
  <div class="container-breadcrumb">
    <!-- <a-breadcrumb class="breadcrumb" separator=">">
      <a-breadcrumb-item v-for="(item, index) in breadList" :key="item.name">
        <router-link v-if="item.name != name && index != 1" :to="{ path: item.path === '' ? '/' : item.path }">
          {{ item.meta.title }}</router-link>
        <span v-else>{{ item.meta.title }}</span>
      </a-breadcrumb-item>
    </a-breadcrumb> -->
    <!--  -->
    <!-- v-show="index<breadList.length-1" -->
    <!-- <el-breadcrumb separator=">">
      <el-breadcrumb-item v-for="(item, index) in breadList" :key="index" :to="{ path: item.path }">
        {{ item.meta.title }}</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-breadcrumb class="breadcrumb" separator=">">
      <el-breadcrumb-item v-for="(level,index)  in this.levelList" :key="index" :to="level.path">
        {{level.name}}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <span>{{nameClass}}</span> -->
  </div>
</template>
<script>
export default {
  name: 'Breadcrumb',
  // data () {
  //   return {
  //     name: '',
  //     breadList: [
  //     ]
  //   }
  // },
  // created () {
  //   this.getBreadcrumb()
  // },
  // watch: {
  //   $route () {
  //     this.getBreadcrumb()
  //   }
  // },
  // methods: {
  //   getBreadcrumb () {
  //     this.breadList = []
  //     this.name = this.$route.name
  //     console.log(this.$route.matched)
  //     this.$route.matched.forEach(item => {
  //       this.breadList.push(item)
  //       this.breadList[0].meta.title = '首页'
  //       this.breadList[0].path = '/homemain'
  //     })
  //   }
  // }
  // data () {
  //   return {
  //     breadList: [] // 路由集合
  //   }
  // },
  // watch: {
  //   $route () {
  //     this.getBreadcrumb()
  //   }
  // },
  // methods: {
  //   isHome (route) {
  //     return route.name === '首页'
  //   },
  //   getBreadcrumb () {
  //     let matched = this.$route.matched
  //     console.log(matched)
  //     // 二级页面三个导航
  //     if (!this.isHome(matched[0])) {
  //       matched = [{ path: '/', meta: { title: '首页' } }].concat(matched)
  //     }
  //     if (matched[matched.length - 1].meta.title === '拍卖会详情') {
  //       matched = [
  //         { path: '/', meta: { title: '首页' } },
  //         { path: '/auctionhome', meta: { title: '全球拍卖会' } }
  //       ].concat(matched[matched.length - 1])
  //     }
  //     if (matched[matched.length - 1].meta.title === '拍卖行详情') {
  //       matched = [{ path: '/', meta: { title: '首页' } }, { path: '/auctionhouse', meta: { title: '拍卖行' } }].concat(matched[matched.length - 1])
  //     }
  //     this.breadList = matched
  //   }
  // },
  // created () {
  //   this.getBreadcrumb()
  // }
  data () {
    return {
      levelList: [
        {
          name: '首页',
          path: '/homemain',
          breadNumber: '0'
        }
      ],
      nameClass: ''
    }
  },
  watch: {
    $route (to, from) {
      this.getBreadcrumb()
    }
  },
  methods: {
    getBreadcrumb () {
      // 1.获取当前的name,path,breadNumber
      var newName = this.$route.meta.title
      var newPath = this.$route.fullPath
      var newBreadNum = this.$route.meta.breadNumber
      // var newTitle = this.$route.meta.title
      // 2.获取前一页存下的name,path,breadNumber
      var oldName = JSON.parse(window.localStorage.getItem('oldName'))
      var oldPath = JSON.parse(window.localStorage.getItem('oldPath'))
      var oldBreadNum = JSON.parse(window.localStorage.getItem('oldBreadNum'))
      var routerInfo = JSON.parse(window.localStorage.getItem('routerInfo')) || []

      this.levelList = routerInfo
      // 3.判断是否是第一层，即初始位置，如果是第一层，分两种情况
      if (this.$route.meta.breadNumber === 1 || this.$route.meta.breadNumber === 9 || this.$route.meta.breadNumber === 3 || this.$route.meta.breadNumber === 8) {
        // 3.1 localStorage有值，那么this.levelList不是空值，说明是由上一个主路由进来的，需要清空之前localStorage的值，并且重新赋值给levelList
        if (this.levelList.length !== 0) {
          localStorage.removeItem('oldName')
          localStorage.removeItem('oldPath')
          localStorage.removeItem('oldBreadNum')
          localStorage.removeItem('routerInfo')
          this.levelList = []
        }
        // 3.2 localStorage没有值，说明就是第一次进主路由，直接赋值
        this.levelList.push({ name: '首页', path: '/homemain', breadNumber: 0 }, { name: newName, path: newPath, breadNumber: newBreadNum })
        // this.$store.commit('breadCrumb/BREAD_PATH',{ newName,newPath,newBreadNum });
        // 按照数组格式的方式存放
        var nameStr = []
        nameStr.push(newName)
        var pathStr = []
        pathStr.push(newPath)
        var breadNumStr = []
        breadNumStr.push(newBreadNum)

        window.localStorage.setItem('oldName', JSON.stringify(nameStr))
        window.localStorage.setItem('oldPath', JSON.stringify(pathStr))
        window.localStorage.setItem('oldBreadNum', JSON.stringify(breadNumStr))
        window.localStorage.setItem('routerInfo', JSON.stringify(this.levelList))
      } else {
        var isBreadNumber = false
        // 4.breadNumber除了等于1，其他值时，this.levalList一定不是空值，判断点击的这个breadNumber数组中是否存在
        // 4.1 如果存在，就要删掉这个后面的所有值，并且将点击所获得的的这个值存进this.levalList数组中
        // 4.2 如果不存在，说明是按照顺序进行的，把它存到localStorage中，并把值给this.leavalList数组
        // 在这里不用return，否则会终止循环
        for (var i = 0; i < this.levelList.length; i++) {
          if (this.levelList[i].breadNumber === newBreadNum) {
            // return true; // 为true时表示数组内已存在 false表示不存在
            isBreadNumber = true
            break
          }
        }

        if (isBreadNumber) {
          // 删除点击的后面所有信息，localStorage中的数据也要删除（这里的删除是截取，而不是完全删除），然后将该信息添加进去
          // 要注意的是，这里的变化还有可能是点击面包屑时产生的，其他地方通常是通过路由跳转得到的
          // 注意这里splice的用法，如果直接写在localStorage.setItem 中，得到的值是被截取的值，而不是截取后的值
          oldName.splice(newBreadNum, oldName.length - newBreadNum)
          window.localStorage.setItem('oldName', JSON.stringify(oldName))

          oldPath.splice(newBreadNum, oldPath.length - newBreadNum)
          window.localStorage.setItem('oldPath', JSON.stringify(oldPath))

          oldBreadNum.splice(newBreadNum, oldBreadNum.length - newBreadNum)
          window.localStorage.setItem('oldBreadNum', JSON.stringify(oldBreadNum))

          routerInfo.splice(newBreadNum, routerInfo.length - newBreadNum)
          window.localStorage.setItem('routerInfo', JSON.stringify(routerInfo))
        } else {
          var oldNameStr = JSON.parse(window.localStorage.getItem('oldName'))
          oldNameStr.push(newName)
          window.localStorage.setItem('oldName', JSON.stringify(oldNameStr))

          var oldPathStr = JSON.parse(window.localStorage.getItem('oldPath'))
          oldPathStr.push(newPath)
          window.localStorage.setItem('oldPath', JSON.stringify(oldPathStr))

          var oldBreadNumStr = JSON.parse(window.localStorage.getItem('oldBreadNum'))
          oldBreadNumStr.push(newBreadNum)
          window.localStorage.setItem('oldBreadNum', JSON.stringify(oldBreadNumStr))

          this.levelList.push({ name: newName, path: newPath, breadNumber: newBreadNum })
          window.localStorage.setItem('routerInfo', JSON.stringify(this.levelList))
        }
      }
    }

  },

  created () {
    var overlat_id = this.$route.fullPath.split('?')
    if (overlat_id[0] === '/overlay') {
      this.nameClass = localStorage.getItem('nameClass')
    }
    this.getBreadcrumb()
  }

}
</script>
<style lang="less" scoped>
.container-breadcrumb {
  height: 65px;
  line-height: 65px;
  border-bottom: 1px solid #dedede;
  margin: 0 100px;
  /deep/ .ant-breadcrumb {
    text-align: left;
    line-height: 65px;
  }
  /deep/ .ant-breadcrumb a:hover {
    color: #cda156;
  }
  /deep/ .ant-breadcrumb-link {
    font-size: 18px;
  }
  /deep/ .el-breadcrumb {
    line-height: inherit;
    font-weight: 400;
    font-size: 18px;
    color: #333;
  }
}
/deep/ .el-breadcrumb__inner {
  font-weight: 400 !important;
  color: #606266 !important;
  &:hover {
    font-weight: 400 !important;
    color: #606266 !important;
  }
}
</style>
